type VALUE_LABEL = {
	label: string;
	value: string;
};

export const TYPES_ATTRIBUTES_LIST: Array<VALUE_LABEL> = [
	{
		label: 'text',
		value: 'text',
	},
	{
		label: 'select',
		value: 'select',
	},
];
export const CATEGORIES_CARRIERS_LIST: Array<VALUE_LABEL> = [
	{
		label: 'categoryA',
		value: 'categoryA',
	},
	{
		label: 'categoryB',
		value: 'categoryB',
	},
	{
		label: 'categoryC',
		value: 'categoryC',
	},
	{
		label: 'categoryD',
		value: 'categoryD',
	},
];
export const TYPE_VEHICLES_LIST: Array<VALUE_LABEL> = [
	{
		label: 'cars',
		value: 'cars',
	},
	{
		label: 'truck',
		value: 'truck',
	},
];

export const LANGUAGES_LIST: Array<VALUE_LABEL> = [
	{ label: 'Español', value: 'es' },
	{ label: 'English', value: 'en' },
];

export const TRACES_TYPE = {
	users: { en: 'Users', es: 'Usuarios' },
	roles: { en: 'Roles', es: 'Roles' },
	nomcods: { en: 'Nomenclators', es: 'Nomencladores' },
	notifications: { en: 'Notifications', es: 'Notificaciones' },
	attributes: { en: 'Attributes', es: 'Atributos' },
	carriers: { en: 'Carriers', es: 'Transportistas' },
	categories: { en: 'Categories', es: 'Categorías' },
	currencyTrace: { en: 'Currency', es: 'Monedas' },
	products: { en: 'Products', es: 'Productos' },
	storages: { en: 'Storages', es: 'Almacenes' },
	storage: { en: 'Storages Product', es: 'Producto de almacén' },
	exchangeRates: { en: 'Exchange rates', es: 'Almacenes' },
	portrait: { en: 'Hero', es: 'Portada' },
	providers: { en: 'Providers', es: 'Proveedores' },
	orders: { en: 'Orders', es: 'Órdenes' },
	storageTransfers: {
		en: 'Storage transfers',
		es: 'Transferencias entre almacenes',
	},
	recipient: { en: 'Recipients', es: 'Destinatarios' },
	buy: { en: 'Buys', es: 'Compras' },
	insertStorage: {
		en: 'inserted into the storage',
		es: 'Insertado al almacén',
	},
	generalConfiguration: {
		en: 'General configuration',
		es: 'Configuración general',
	},
	pagesBasic: { en: 'Basic page', es: 'Página básica' },
	cartShops: { en: 'Cart shop', es: 'Carrito de compras' },
	cartShopsWholesaler: {
		en: 'Cart shop wholesaler',
		es: 'Carrito de compras mayorista',
	},
	ordersWholesaler: {
		en: 'Orders wholesaler',
		es: 'Órdenes mayorista',
	},
	advertisings: { en: 'Publicidad', es: 'Publicidad' },
	wholesalerRequest: {
		en: 'Solicitud de mayorista',
		es: 'Solicitud de mayorista',
	},
	socialMedia: { en: 'Interest links', es: 'Enlaces de interés' },
	storageExit: { en: 'Storage exit', es: 'Salida de almacén' },
	transform: { en: 'Product transformation', es: 'Transformación de producto' },
	quotes: { en: 'Quotes', es: 'Frases' },
	clients: { en: 'Clients', es: 'Clientes' },
	paymentGateway: { en: 'Payment Gateway', es: 'Pasarela de pago' },
	ipv: { en: 'IPV', es: 'IPV' },
	sales: { en: 'Sales', es: 'Venta' },
};

export const TRACES_TYPE_ARRAY = [
	{ label: { en: 'User', es: 'Usuarios' }, value: 'users' },
	{ label: { en: 'Roles', es: 'Roles' }, value: 'roles' },
	{ label: { en: 'Nomenclators', es: 'Nomencladores' }, value: 'nomcods' },
	{
		label: { en: 'Notifications', es: 'Notificaciones' },
		value: 'notifications',
	},
	{ label: { en: 'Attributes', es: 'Atributos' }, value: 'attributes' },
	{ label: { en: 'Carriers', es: 'Transportistas' }, value: 'carriers' },
	{ label: { en: 'Categories', es: 'Categorías' }, value: 'categories' },
	{ label: { en: 'Currency', es: 'Monedas' }, value: 'currencies' },
	{ label: { en: 'Products', es: 'Productos' }, value: 'products' },
	{ label: { en: 'Storages', es: 'Almacenes' }, value: 'storages' },
	{
		label: { en: 'Exchange rates', es: 'Tasas de cambio' },
		value: 'exchangeRates',
	},
	{ label: { en: 'Hero', es: 'Portada' }, value: 'hero' },
	{ label: { en: 'Orders', es: 'Órdenes' }, value: 'orders' },
	{
		label: { en: 'General configuration', es: 'Configuración general' },
		value: 'generalConfiguration',
	},
	{
		label: { en: 'Social links', es: 'Redes sociales' },
		value: 'socialMedia',
	},
	{
		label: { en: 'Product transformation', es: 'Transformación de producto' },
		value: 'transform',
	},
	{
		label: { en: 'Basic page', es: 'Página básica' },
		value: 'pagesBasic',
	},
	{
		label: { en: 'Cart shop', es: 'Carrito de compras' },
		value: 'cartShops',
	},
	{
		label: { en: 'Storage exit', es: 'Salida de almacén' },
		value: 'storageExit',
	},
	{
		label: {
			en: 'Storage transfers',
			es: 'Transferencias entre almacenes',
		},
		value: 'storageTransfers',
	},
	{
		label: { en: 'Recipients', es: 'Destinatarios' },
		value: 'recipient',
	},
	{
		label: { en: 'Buys', es: 'Compras' },
		value: 'buy',
	},
	{
		label: { en: 'Storage exit', es: 'Salida de almacén' },
		value: 'storageExit',
	},
	{
		label: { en: 'Advertisings', es: 'Publicidades' },
		value: 'advertisings',
	},
	{
		label: { en: 'IPV', es: 'IPV' },
		value: 'ipv',
	},
	{
		label: { en: 'Sales', es: 'Venta' },
		value: 'sales',
	},
	{
		label: { en: 'Payment Gateway', es: 'Pasarela de pago' },
		value: 'paymentGateway',
	},
	{
		label: { en: 'Clients', es: 'Clientes' },
		value: 'clients',
	},
	{
		label: { en: 'Quotes', es: 'Frases' },
		value: 'quotes',
	},
];

export const TRACES_ACTIONS = {
	createNomenclator: {
		en: 'has created the nomenclator',
		es: 'ha creado el nomenclador',
	},
	updateNomenclator: {
		en: 'has updated the nomenclator',
		es: 'ha modificado el nomenclador',
	},
	deleteNomenclator: {
		en: 'has deleted the nomenclator',
		es: 'ha eliminado el nomenclador',
	},

	createUser: { en: 'has created the user', es: 'ha creado el usuario' },
	updateUser: { en: 'has updated the user', es: 'ha modificado el usuario' },
	deleteUser: { en: 'has deleted the user', es: 'ha eliminado el usuario' },

	activateUser: { en: 'has activated the user', es: 'ha activado el usuario' },
	deactivateUser: {
		en: 'has deactivated the user',
		es: 'ha desactivado el usuario',
	},
	changePasswordClient: {
		en: 'has changed the client password',
		es: 'ha cambiado la contraseña del usuario web',
	},
	changeAdminPassword: {
		en: 'has changed the administrative password',
		es: 'ha cambiado la contraseña de administración',
	},
	updateUserImage: {
		en: 'has updated the user image',
		es: 'ha actualizado la imagen del usuario',
	},
	createRole: {
		en: 'has created user the role',
		es: 'ha creado el rol de usuario',
	},
	updateRole: {
		en: 'has updated the user role',
		es: 'ha modificado el rol de usuario',
	},
	deleteRole: {
		en: 'has deleted user role',
		es: 'ha eliminado el rol de usuario',
	},

	createAttribute: {
		en: 'has created the attribute',
		es: 'ha creado el atributo',
	},
	updateAttribute: {
		en: 'has updated the attribute',
		es: 'ha modificado el atributo',
	},
	deleteAttribute: {
		en: 'has deleted the attribute',
		es: 'ha eliminado el atributo',
	},
	activateDeactivateAttrib: {
		en: 'has activated/deactivated the attribute',
		es: 'ha activado/desactivado el atributo',
	},
	createCarrier: {
		en: 'has created the carrier with licence number',
		es: 'ha creado el transportista con número de licencia',
	},
	updateCarrier: {
		en: 'has updated the carrier with licence number',
		es: 'ha modificado el transportista con número de licencia',
	},
	deleteCarrier: {
		en: 'has deleted the carrier with licence number',
		es: 'ha eliminado el transportista con número de licencia',
	},
	activateDeactiveCarrier: {
		en: 'has activated/deactivated the carrier with licence number',
		es: 'ha activado/desactivado el transportista con número de licencia',
	},
	createCategory: {
		en: 'has created the category',
		es: 'ha creado la categoría',
	},
	updateCategory: {
		en: 'has updated the category',
		es: 'ha modificado la categoría',
	},
	deleteCategory: {
		en: 'has deleted the category',
		es: 'ha eliminado la categoría',
	},
	activateDeactiveCategory: {
		en: 'has activated/deactivated the category',
		es: 'ha activado/desactivado la categoría',
	},
	createCurrency: { en: 'has created the currency', es: 'ha creado la moneda' },
	updateCurrency: {
		en: 'has updated the currency',
		es: 'ha modificado la moneda',
	},
	deleteCurrency: {
		en: 'has deleted the currency',
		es: 'ha eliminado la moneda',
	},
	activateDeactiveCurrency: {
		en: 'has activated/deactivated the currency',
		es: 'ha activado/desactivado la moneda',
	},
	createProduct: { en: 'has created the product', es: 'ha creado el producto' },
	updateProduct: {
		en: 'has updated the product',
		es: 'ha modificado el producto',
	},
	deleteProduct: {
		en: 'has deleted the product',
		es: 'ha eliminado el producto',
	},
	activateDeactiveProduct: {
		en: 'has activated/deactivated the product',
		es: 'ha activado/desactivado el producto',
	},
	createRecipient: {
		en: 'has created the recipient',
		es: 'ha creado el destinatario',
	},
	updateRecipient: {
		en: 'has updated the recipient',
		es: 'ha modificado el destinatario',
	},
	deleteRecipient: {
		en: 'has deleted the recipient',
		es: 'ha eliminado el destinatario',
	},
	createExchangeRate: {
		en: 'has created the exchange rate',
		es: 'ha creado la tasa de cambio',
	},
	updateExchangeRate: {
		en: 'has updated the exchange rate',
		es: 'ha modificado la tasa de cambio',
	},
	deleteExchangeRate: {
		en: 'has deleted the exchange rate',
		es: 'ha eliminado la tasa de cambio',
	},

	activateDeactiveExchangeRate: {
		en: 'has activated/deactivated the exchange rate',
		es: 'ha activado/desactivado la tasa de cambio',
	},
	createHero: {
		en: 'has created the hero image',
		es: 'ha creado la portada',
	},
	updateHero: {
		en: 'has updated the hero image',
		es: 'ha modificado la portada',
	},
	deleteHero: {
		en: 'has deleted the hero image',
		es: 'ha eliminado la portada',
	},

	activateDeactiveHero: {
		en: 'has activated/deactivated the hero image',
		es: 'ha activado/desactivado la portada',
	},
	createProvider: {
		en: 'has created the provider',
		es: 'ha creado el proveedor',
	},
	updateProvider: {
		en: 'has updated the provider',
		es: 'ha modificado el proveedor',
	},
	deleteProvider: {
		en: 'has deleted the provider',
		es: 'ha eliminado el proveedor',
	},
	createOrder: {
		en: 'has created the order',
		es: 'ha creado la orden',
	},
	changeOrderStatus: {
		en: 'has changed the order status',
		es: 'ha cambiado el estado de la orden de compra',
	},
	cancelOrder: {
		en: 'has cancelled the order',
		es: 'ha cancelado la orden de compra',
	},
	deleteOrder: {
		en: 'has deleted the order',
		es: 'ha eliminado la orden de compra',
	},

	updatedPercent: {
		en: 'has updated the discount percentage para',
		es: 'ha actualizado el porciento de descuento para',
	},
	createStorageTransfer: {
		en: 'has created the storage transfer',
		es: 'ha creado la transferencia entre almacenes',
	},
	updateStorageTransfer: {
		en: 'has updated the the storage transfer',
		es: 'ha modificado la transferencia entre almacenes',
	},
	cancelStorageTransfer: {
		en: 'has cancelled the storage transfer',
		es: 'ha cancelado la transferencia entre almacenes',
	},
	confirmStorageTransfer: {
		en: 'has confirmed the storage transfer',
		es: 'ha confirmado la transferencia entre almacenes',
	},
	createBuy: {
		en: 'has created the buy',
		es: 'ha creado la compra',
	},
	updateBuy: {
		en: 'has updated the buy',
		es: 'ha modificado la compra',
	},
	confirmBuy: {
		en: 'has confirmed the buy',
		es: 'ha confirmado la compra',
	},
	deleteBuy: {
		en: 'has deleted the buy',
		es: 'ha eliminado la compra',
	},
	createGeneralConfiguration: {
		en: 'has created attribute',
		es: 'ha creado el atributo',
	},
	updateGeneralConfiguration: {
		en: 'has updated the attribute',
		es: 'ha modificado el atributo',
	},
	deleteGeneralConfiguration: {
		en: 'has deleted the attribute',
		es: 'ha eliminado el atributo',
	},
	activateDeactivateGeneralConfig: {
		en: 'has activated/deactivated the attribute',
		es: 'ha activado/desactivado el atributo',
	},
	createPageBasic: {
		en: 'has created the basic page',
		es: 'ha creado la página básica',
	},
	updatePageBasic: {
		en: 'has updated the basic page',
		es: 'ha modificado la página básica',
	},
	deletePageBasic: {
		en: 'has deleted the basic page',
		es: 'ha eliminado la página básica',
	},
	activateDeactivePageBasic: {
		en: 'has activated/deactivated the basic page',
		es: 'ha activado/desactivado la página básica ',
	},
	createsocialNetworks: {
		en: 'has created the interest links',
		es: 'ha creado los enlaces de interés',
	},
	updatesocialNetworks: {
		en: 'has updated the interest links',
		es: 'ha modificado los enlaces de interés',
	},
	deletesocialNetworks: {
		en: 'has deleted the interest links',
		es: 'ha eliminado los enlaces de interés',
	},
	activateDeactivesocialNetworks: {
		en: 'has activated/deactivated the interest links',
		es: 'ha activado/desactivado los enlaces de interés ',
	},
	// CART SHOP
	createCartShop: {
		en: 'has added the product and has created the cart shop',
		es: 'ha añadido el producto y ha creado el carrito de compras',
	},
	editCartShop: {
		en: 'has updated the cart shop',
		es: 'ha modificado el carrito de compras',
	},
	editProductCartShop: {
		en: 'has updated the amount and the total price of the product added to the cart shop',
		es: 'ha modificado la cantidad y el precio total del producto añadido al carrito de compras',
	},
	deleteCartShop: {
		en: 'has deleted the product from the cart shop',
		es: 'ha eliminado the product del carrito de compras',
	},
	deleteProductCartShop: {
		en: 'has deleted the product from the cart shop',
		es: 'ha eliminado el producto del carrito de compras',
	},
	cancelCartShop: {
		en: 'has cancelled cart shop',
		es: 'ha cancelado el carrito de compras',
	},
	setPayMethodCartShop: {
		en: 'has set the payment method to the cart shop',
		es: 'ha establecido el método de pago al carrito de compras',
	},
	paidCartShop: {
		en: 'has paid the cart shop',
		es: 'ha pagado el carrito de compras',
	},
	assignRecipients: {
		en: 'has assigned the recipient to the cart shop',
		es: 'ha asignado los destinatarios al carrito de compras',
	},
	// STORAGE Exit STATE
	createStorageExit: {
		en: 'has created the storage exit',
		es: 'ha creado la salida de almacén',
	},
	editStorageExit: {
		en: 'has updated the storage exit',
		es: 'has modificado la salida de almacén',
	},
	confirmStorageExit: {
		en: 'has confirmed the storage exit',
		es: 'ha confirmado la salida de almacén',
	},
	cancelStorageExit: {
		en: 'has cancelled the storage exit',
		es: 'ha cancelado la salida de almacén',
	},

	createTransform: {
		en: 'has created the product transformation',
		es: 'ha creado la transformación de producto',
	},
	updateTransform: {
		en: 'has updated the product transformation',
		es: 'ha modificado la transformación de producto',
	},
	confirmTransform: {
		en: 'has confirmed the product transformation',
		es: 'ha confirmado la transformación de producto',
	},
	cancelTransform: {
		en: 'has cancelled the product transformation',
		es: 'ha cancelado la transformación de producto',
	},

	createAdvertising: {
		en: 'has created the advertising',
		es: 'ha creado la publicidad',
	},
	updateAdvertising: {
		en: 'has updated the advertising',
		es: 'ha modificado la publicidad',
	},
	deleteAdvertising: {
		en: 'has deleted the advertising',
		es: 'ha eliminado la publicidad',
	},
	activateDeactivateAdvertising: {
		en: 'has activated/deactivated the advertising',
		es: 'ha activado/desactivado la publicidad',
	},
	createPortrait: {
		en: 'has created a portrait',
		es: 'ha creado una portada',
	},
	editPortrait: {
		en: 'has edited a portrait',
		es: 'ha editado una portada',
	},
	deletPortrait: {
		en: 'has deleted a portrait',
		es: 'ha eliminado una portada',
	},
	productInsertedStorage: {
		en: 'has inserted a product into the storage',
		es: 'ha insertado un producto en el almacén',
	},
	createWholesalerRequest: {
		en: 'has applied to be a wholesaler',
		es: 'ha solicitado ser mayorista',
	},
	updateWholesalerRequest: {
		en: 'has updated requested to be a wholesaler',
		es: 'ha actualizado solicitado ser mayorista',
	},
	deleteWholesalerRequest: {
		en: 'has removed the request for wholesaler',
		es: 'ha eliminado la solicitado para mayorista',
	},
	notApprovedWholesalerRequest: {
		en: 'has failed the application for wholesaler',
		es: 'ha desaprobado la solicitud para mayorista',
	},
	approvedWholesalerRequest: {
		en: 'has approved the application for wholesaler',
		es: 'ha aprobado la solicitud para mayorista',
	},
	deleteStorageProduct: {
		en: 'has deleted from the storage the product:',
		es: 'ha eliminado del almacén el producto:',
	},
	activeStorageProduct: {
		en: 'has activated from the storage the product:',
		es: 'ha activado del almacén el producto:',
	},
	deactivateStorageProduct: {
		en: 'has deactivated from the storage the product:',
		es: 'ha desactivado del almacén el producto:',
	},
	createQuote: {
		en: 'has created the quote',
		es: 'ha creado la frase',
	},
	updateQuote: {
		en: 'has updated the quote',
		es: 'ha actualizado la frase',
	},
	deleteQuote: {
		en: 'has deleted the quote',
		es: 'ha eliminado la frase',
	},
	activateQuote: {
		en: 'has activated the quote',
		es: 'ha activado la frase',
	},
	deactivateQuote: {
		en: 'has deactivated the quote',
		es: 'ha desactivado la frase',
	},
	createPaymentGateway: {
		en: 'has created a payment gateway',
		es: 'ha creado una pasarela de pago',
	},
	editPaymentGateway: {
		en: 'has edited a payment gateway',
		es: 'ha editado una pasarela de pago',
	},
	deletePaymentGateway: {
		en: 'has deleted a payment gateway',
		es: 'ha eliminado una pasarela de pago',
	},
	activatePaymentGateway: {
		en: 'has activated the payment gateway',
		es: 'ha activado la pasarela de pago',
	},
	deactivatePaymentGateway: {
		en: 'has deactivated the payment gateway',
		es: 'ha desactivado la pasarela de pago',
	},
	createIPV: {
		en: 'has created a ipv',
		es: 'ha creado un ipv',
	},
	editIPV: {
		en: 'has edited a ipv',
		es: 'ha editado un ipv',
	},
	deleteIPV: {
		en: 'has canceled a ipv',
		es: 'ha cancelado un ipv',
	},
	confirmIPV: {
		en: 'has confirmed a ipv',
		es: 'ha confirmado un ipv',
	},
	confirmCommercialIPV: {
		en: 'has confirmed a ipv by commercial',
		es: 'ha confirmado un ipv por comercial',
	},
	activateIPV: {
		en: 'has activated the ipv',
		es: 'ha activado la ipv',
	},
	deactivateIPV: {
		en: 'has deactivated the ipv',
		es: 'ha desactivado la ipv',
	},
	createSale: {
		en: 'has created a sale',
		es: 'ha creado una venta',
	},
	editSale: {
		en: 'has edited a sale',
		es: 'ha editado una venta',
	},
	deleteSale: {
		en: 'has canceled a sale',
		es: 'ha cancelado una venta',
	},
	confirmSale: {
		en: 'has confirmed a sale',
		es: 'ha confirmado una venta',
	},
	confirmSalePaid: {
		en: 'has confirmed payment for a sale',
		es: 'ha confirmado el pago de una venta',
	},

	createClient: {
		en: 'has created a client',
		es: 'ha creado un cliente',
	},
	editClient: {
		en: 'has edited a client',
		es: 'ha editado un cliente',
	},
	deleteClient: {
		en: 'has deleted a client',
		es: 'ha eliminado un cliente',
	},
	activateClient: {
		en: 'has activated the client',
		es: 'ha activado el cliente',
	},
	deactivateClient: {
		en: 'has deactivated the client',
		es: 'ha desactivado el cliente',
	},
	createBusinessRule: {
		en: 'has created a business rule',
		es: 'ha creado un regla de negocio',
	},
	editBusinessRule: {
		en: 'has edited a business rule',
		es: 'ha editado un regla de negocio',
	},
	deleteBusinessRule: {
		en: 'has deleted a business rule',
		es: 'ha eliminado un regla de negocio',
	},
	activateBusinessRule: {
		en: 'has activated the business rule',
		es: 'ha activado el regla de negocio',
	},
	deactivateBusinessRule: {
		en: 'has deactivated the business rule',
		es: 'ha desactivado el regla de negocio',
	},
	createTransfer: {
		en: 'has created a pending transfer',
		es: 'ha creado una transferencia pendiente',
	},
	deniedTransfer: {
		en: 'has denied a pending transfer',
		es: 'ha denegado una transferencia pendiente',
	},
	confirmTransfer: {
		en: 'has confirmed a pending transfer',
		es: 'ha confirmado una transferencia pendiente',
	},
	depositFundsConfirmed: {
		en: 'has confirmed a deposited funds ',
		es: 'ha confirmado un depositado de fondos ',
	},
};

export const ORDER_STATUS_LIST = [
	{ label: { en: 'Paid', es: 'Pagado' }, value: 'paid' },
	{ label: { en: 'Pending', es: 'Pendiente' }, value: 'pending' },
	{ label: { en: 'Validated', es: 'Validado' }, value: 'validated' },
	{ label: { en: 'Processing', es: 'Procesando' }, value: 'processing' },
	{ label: { en: 'Shipping', es: 'Enviando' }, value: 'shipping' },
	{
		label: { en: 'Ready to deliver', es: 'Listo para entregar' },
		value: 'ready',
	},
	{ label: { en: 'Delivered', es: 'Entregado' }, value: 'delivered' },
	{ label: { en: 'Completed', es: 'Completado' }, value: 'completed' },
];
export const ORDER_STATUS: Record<
	string,
	{
		es: string;
		en: string;
	}
> = {
	pending: { en: 'Pending Request', es: 'Solicitud pendiente' },
	paid: { en: 'Paid', es: 'Pagado' },
	validated: { en: 'Validated', es: 'Validado' },
	processing: { en: 'Processing', es: 'Procesando' },
	cancelled: { en: 'Canceled', es: 'Cancelado' },
	cancelledByClient: { en: 'Canceled by client', es: 'Cancelado por cliente' },
	shipping: { en: 'Shipping', es: 'Enviando' },
	ready: { en: 'Ready to deliver', es: 'Listo para entregar' },
	delivered: { en: 'Delivered', es: 'Entregado' },
	completed: { en: 'Completed', es: 'Completado' },
};

export const TRACKING_STATUS_WHOLESALE: Array<{
	label: { en: string; es: string };
	value: string;
	description: { en: string; es: string };
}> = [
	{
		label: { en: 'Pending Request', es: 'Solicitud pendiente' },
		description: {
			en: 'The order has been requested',
			es: 'El pedido ha sido solicitado',
		},
		value: 'pending',
	},
	{
		label: { en: 'Paid', es: 'Pagado' },
		description: {
			en: 'The order has been paid',
			es: 'El pedido ha sido pagado',
		},
		value: 'paid',
	},
	{
		label: { en: 'Validated', es: 'Validado' },
		description: {
			en: 'The order has been validated',
			es: 'El pedido ha sido validado',
		},
		value: 'validated',
	},
	{
		label: { en: 'Processing', es: 'Procesando' },
		description: {
			en: 'The order is being processed',
			es: 'El pedido está siendo procesado',
		},

		value: 'processing',
	},
	{
		label: { en: 'Shipping', es: 'Enviando' },
		description: {
			en: 'The order is being shipped',
			es: 'El pedido está siendo enviado',
		},

		value: 'shipping',
	},
	{
		label: { en: 'Ready to deliver', es: 'Listo para entregar' },
		description: {
			en: 'The order is ready to be delivered',
			es: 'El pedido está listo para ser entregado',
		},
		value: 'ready',
	},
	{
		label: { en: 'Delivered', es: 'Entregado' },
		description: {
			en: 'The order has been delivered',
			es: 'El pedido ha sido entregado',
		},
		value: 'delivered',
	},
	{
		label: { en: 'Completed', es: 'Completado' },
		description: {
			en: 'The order has been completed',
			es: 'El pedido ha sido completado',
		},
		value: 'completed',
	},
];
export const TRACKING_STATUS: Array<{
	label: { en: string; es: string };
	value: string;
	description: { en: string; es: string };
}> = [
	{
		label: { en: 'Paid', es: 'Pagado' },
		description: {
			en: 'The order has been paid',
			es: 'El pedido ha sido pagado',
		},
		value: 'paid',
	},
	{
		label: { en: 'Validated', es: 'Validado' },
		description: {
			en: 'The order has been validated',
			es: 'El pedido ha sido validado',
		},
		value: 'validated',
	},
	{
		label: { en: 'Processing', es: 'Procesando' },
		description: {
			en: 'The order is being processed',
			es: 'El pedido está siendo procesado',
		},

		value: 'processing',
	},
	{
		label: { en: 'Shipping', es: 'Enviando' },
		description: {
			en: 'The order is being shipped',
			es: 'El pedido está siendo enviado',
		},

		value: 'shipping',
	},
	{
		label: { en: 'Ready to deliver', es: 'Listo para entregar' },
		description: {
			en: 'The order is ready to be delivered',
			es: 'El pedido está listo para ser entregado',
		},
		value: 'ready',
	},
	{
		label: { en: 'Delivered', es: 'Entregado' },
		description: {
			en: 'The order has been delivered',
			es: 'El pedido ha sido entregado',
		},
		value: 'delivered',
	},
	{
		label: { en: 'Completed', es: 'Completado' },
		description: {
			en: 'The order has been completed',
			es: 'El pedido ha sido completado',
		},
		value: 'completed',
	},
];

export const PROVINCES: Array<{
	value: string;
	label: string;
	municipalities: Array<{
		value: string;
		label: string;
	}>;
}> = [
	{
		value: '1',
		label: 'Pinar del Río',
		municipalities: [
			{ label: 'Consolación del Sur', value: '1' },
			{ label: 'Guane', value: '2' },
			{ label: 'La Palma', value: '3' },
			{ label: 'Los Palacios', value: '4' },
			{ label: 'Mantua', value: '5' },
			{ label: 'Minas de Matahambre', value: '6' },
			{ label: 'Pinar del Río', value: '7' },
			{ label: 'San Juan y Martínez', value: '8' },
			{ label: 'San Luis', value: '9' },
			{ label: 'Sandino', value: '10' },
			{ label: 'Viñales', value: '11' },
		],
	},
	{
		value: '2',
		label: 'Artemisa',
		municipalities: [
			{ label: 'Alquízar', value: '1' },
			{ label: 'Artemisa', value: '2' },
			{ label: 'Bauta', value: '3' },
			{ label: 'Caimito', value: '4' },
			{ label: 'Guanajay', value: '5' },
			{ label: 'Güira de Melena', value: '6' },
			{ label: 'Mariel', value: '7' },
			{ label: 'San Antonio de los Baños', value: '8' },
			{ label: 'Bahía Honda', value: '9' },
			{ label: 'San Cristóbal', value: '10' },
			{ label: 'Candelaria', value: '11' },
		],
	},
	{
		value: '3',
		label: 'Mayabeque',
		municipalities: [
			{ label: 'Batabanó', value: '1' },
			{ label: 'Bejucal', value: '2' },
			{ label: 'Güines', value: '3' },
			{ label: 'Jaruco', value: '4' },
			{ label: 'Madruga', value: '5' },
			{ label: 'Melena del Sur', value: '6' },
			{ label: 'Nueva Paz', value: '7' },
			{ label: 'Quivicán', value: '8' },
			{ label: 'San José de las Lajas', value: '9' },
			{ label: 'San Nicolás de Bari', value: '10' },
			{ label: 'Santa Cruz del Norte', value: '11' },
		],
	},
	{
		value: '4',
		label: 'La Habana',
		municipalities: [
			{ label: 'Arroyo Naranjo', value: '1' },
			{ label: 'Boyeros', value: '2' },
			{ label: 'Centro Habana', value: '3' },
			{ label: 'Cerro', value: '4' },
			{ label: 'Cotorro', value: '5' },
			{ label: 'Diez de Octubre', value: '6' },
			{ label: 'Guanabacoa', value: '7' },
			{ label: 'Habana del Este', value: '8' },
			{ label: 'Habana Vieja', value: '9' },
			{ label: 'La Lisa', value: '10' },
			{ label: 'Marianao', value: '11' },
			{ label: 'Playa', value: '12' },
			{ label: 'Plaza', value: '13' },
			{ label: 'Regla', value: '14' },
			{ label: 'San Miguel del Padrón', value: '16' },
		],
	},
	{
		value: '5',
		label: 'Matanzas',
		municipalities: [
			{ label: 'Calimete', value: '1' },
			{ label: 'Cárdenas', value: '2' },
			{ label: 'Ciénaga de Zapata', value: '3' },
			{ label: 'Colón', value: '4' },
			{ label: 'Jagüey Grande', value: '5' },
			{ label: 'Jovellanos', value: '6' },
			{ label: 'Limonar', value: '7' },
			{ label: 'Los Arabos', value: '8' },
			{ label: 'Martí', value: '9' },
			{ label: 'Matanzas', value: '10' },
			{ label: 'Pedro Betancourt', value: '11' },
			{ label: 'Perico', value: '12' },
			{ label: 'Unión de Reyes', value: '13' },
		],
	},
	{
		value: '6',
		label: 'Cienfuegos',
		municipalities: [
			{ label: 'Abreus', value: '1' },
			{ label: 'Aguada de Pasajeros', value: '2' },
			{ label: 'Cienfuegos', value: '3' },
			{ label: 'Cruces', value: '4' },
			{ label: 'Cumanayagua', value: '5' },
			{ label: 'Palmira', value: '6' },
			{ label: 'Rodas', value: '7' },
			{ label: 'Santa Isabel de las Lajas', value: '8' },
		],
	},
	{
		value: '7',
		label: 'Villa Clara',

		municipalities: [
			{ label: 'Caibarién', value: '1' },
			{ label: 'Camajuaní', value: '2' },
			{ label: 'Cifuentes', value: '3' },
			{ label: 'Corralillo', value: '4' },
			{ label: 'Encrucijada', value: '5' },
			{ label: 'Manicaragua', value: '6' },
			{ label: 'Placetas', value: '7' },
			{ label: 'Quemado de Güines', value: '8' },
			{ label: 'Ranchuelo', value: '0' },
			{ label: 'Remedios', value: '10' },
			{ label: 'Sagua la Grande', value: '11' },
			{ label: 'Santa Clara', value: '12' },
			{ label: 'Santo Domingo', value: '13' },
		],
	},
	{
		value: '8',
		label: 'Sancti Spíritus',
		municipalities: [
			{ label: 'Cabaigúan', value: '1' },
			{ label: 'Fomento', value: '2' },
			{ label: 'Jatibonico', value: '3' },
			{ label: 'La Sierpe', value: '4' },
			{ label: 'Sancti Spíritus', value: '5' },
			{ label: 'Taguasco', value: '6' },
			{ label: 'Trinidad', value: '7' },
			{ label: 'Yaguajay', value: '8' },
		],
	},
	{
		value: '9',
		label: 'Ciego de Ávila',
		municipalities: [
			{ label: 'Ciro Redondo', value: '1' },
			{ label: 'Baraguá', value: '2' },
			{ label: 'Bolivia', value: '3' },
			{ label: 'Chambas', value: '4' },
			{ label: 'Ciego de Ávila', value: '5' },
			{ label: 'Florencia', value: '6' },
			{ label: 'Majagua', value: '7' },
			{ label: 'Morón', value: '8' },
			{ label: 'Primero de Enero', value: '9' },
			{ label: 'Venezuela', value: '10' },
		],
	},
	{
		value: '10',
		label: 'Camagüey',
		municipalities: [
			{ label: 'Camagüey', value: '1' },
			{ label: 'Carlos Manuel de Céspedes', value: '2' },
			{ label: 'Esmeralda', value: '3' },
			{ label: 'Florida', value: '4' },
			{ label: 'Guaimaro', value: '5' },
			{ label: 'Jimagüayú', value: '6' },
			{ label: 'Minas', value: '7' },
			{ label: 'Najasa', value: '8' },
			{ label: 'Nuevitas', value: '9' },
			{ label: 'Santa Cruz del Sur', value: '10' },
			{ label: 'Sibanicú', value: '11' },
			{ label: 'Sierra de Cubitas', value: '12' },
			{ label: 'Vertientes', value: '13' },
		],
	},
	{
		value: '11',
		label: 'Las Tunas',
		municipalities: [
			{ label: 'Amancio Rodríguez', value: '1' },
			{ label: 'Colombia', value: '2' },
			{ label: 'Jesús Menéndez', value: '3' },
			{ label: 'Jobabo', value: '4' },
			{ label: 'Las Tunas', value: '5' },
			{ label: 'Majibacoa', value: '6' },
			{ label: 'Manatí', value: '7' },
			{ label: 'Puerto Padre', value: '8' },
		],
	},
	{
		value: '12',
		label: 'Holguín',
		municipalities: [
			{ label: 'Antilla', value: '1' },
			{ label: 'Báguanos', value: '2' },
			{ label: 'Banes', value: '3' },
			{ label: 'Cacocum', value: '4' },
			{ label: 'Calixto García', value: '5' },
			{ label: 'Cueto', value: '6' },
			{ label: 'Frank País', value: '7' },
			{ label: 'Gibara', value: '8' },
			{ label: 'Holguín', value: '9' },
			{ label: 'Mayarí', value: '10' },
			{ label: 'Moa', value: '11' },
			{ label: 'Rafael Freyre', value: '12' },
			{ label: 'Sagua de Tánamo', value: '13' },
			{ label: 'Urbano Noris', value: '14' },
		],
	},
	{
		value: '13',
		label: 'Santiago de Cuba',
		municipalities: [
			{ label: 'Contramaestre', value: '1' },
			{ label: 'Guamá', value: '2' },
			{ label: 'Julio Antonio Mella', value: '3' },
			{ label: 'Palma Soriano', value: '4' },
			{ label: 'San Luis', value: '5' },
			{ label: 'Santiago de Cuba', value: '6' },
			{ label: 'Segundo Frente', value: '7' },
			{ label: 'Songo la Maya', value: '8' },
			{ label: 'Tercer Frente', value: '9' },
		],
	},
	{
		value: '14',
		label: 'Guantánamo',
		municipalities: [
			{ label: 'Baracoa', value: '1' },
			{ label: 'Caimanera', value: '2' },
			{ label: 'El Salvador', value: '3' },
			{ label: 'Guantánamo', value: '4' },
			{ label: 'Imías', value: '5' },
			{ label: 'Maisí', value: '6' },
			{ label: 'Manuel Tames', value: '7' },
			{ label: 'Niceto Pérez', value: '8' },
			{ label: 'San Antonio del Sur', value: '9' },
			{ label: 'Yateras', value: '10' },
		],
	},
	{
		value: '15',
		label: 'Isla de la Juventud',
		municipalities: [],
	},
	{
		value: '16',
		label: 'Granma',
		municipalities: [
			{ label: 'Bartolomé Masó', value: '1' },
			{ label: 'Bayamo', value: '2' },
			{ label: 'Buey Arriba', value: '3' },
			{ label: 'Campechuela', value: '4' },
			{ label: 'Cauto Cristo', value: '5' },
			{ label: 'Guisa', value: '6' },
			{ label: 'Jiguaní', value: '7' },
			{ label: 'Manzanillo', value: '8' },
			{ label: 'Media Luna', value: '9' },
			{ label: 'Niquero', value: '10' },
			{ label: 'Pilón', value: '11' },
			{ label: 'Río Cauto', value: '12' },
			{ label: 'Yara', value: '13' },
		],
	},
];
